import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigurationService {

  result:any;
  url:any;
  constructor() { }

  getEnvironment() {
    let envConfig: any;
    envConfig = environment
    return envConfig;
  }
  getConfigurations() {
    this.result = this.getEnvironment();
    this.url = this.result['protocol'] + "://" + this.result['ip_address'];
    return this.url;
  }
  getRequiredApi(apiKey: string) {
    let apiUrls: any = {
        "oauth_Token": "/oauth/token",
        "user_logout": "/oauth/revoke-token",
        "user_details":"/user",
        "user_plants":"/user/bp/{user_id}",
        "gd_accessobjects":"/gd/access/objs",
        "fwd_password":"/users/reset/link",
        "reset_pwd_resetKey": "/users/resetpwd/{resetKey}",
        "password_reset":"/users/resetpwd",
        //
        "delete_org":"/org/delete",
        "create_org":"/org/add",
        "update_org":"/org/update",

        //Login Captcha
        "get_authenticated_captcha":"/process/captcha",

        //roles
        "om_get_org_roles":"/org/roles",
        "om_get_org_roles_id": "/org/roles/{role_id}",
        "om_post_role_create": "/org/roles",
        "om_put_role_update": "/org/roles",
        "om_put_role_rename": "/org/roles/{role_id}",
        "om_delete_role": "/org/roles/",
        
        //manage user 
        "check_approvals" : "/check/user/approvals",
        "add_user":"/org/bp/users",
        "update_user":"/org/bp/users",
        "get_org_users":"/org/users",
        "delete_org_user":"/org/users/delete",
        "isexisting_user":"/org/isexisting/orguser",
        "get_userIds":"/user/getUserIds",
        "get_deactivated_users":"/org/users",
        "get_user_types":"/org/usertypes",

        //business place
        "get_org_opus":"/org/plants", // get all opu id and name of that org
        "get_bps_list":"/org/bps", // for specific user / all_bp
        "delete_bp":"/org/delete/bp",
        "update_bp":"/org/bp", // update opu put method
        "create_bp":"/org/bp", //create opu post method
        "get_list_objects":"/gd/list/{obj_name}",
        
        //config values
        "get_config_group":"/gd/config/group",
        "email_templates": "/wf/getEmailTempDet",
        "get_Doc_config_group":"/gdDocument/config/group",
        "save_config_values":"/gd/upload/configValues",

        //masters 
        'get_masters':'/gd/masters/',
        'get_masters_filter':'/gd/masters/filter/',
        'listOfAudits':"/getAuditList",
        'initializeAudit':'/create/audit',
        'export_masters':'/reports/download',
        'get_audit_by_id':'/getAuditById',
        'get_dealer_by_id':'/gd/get/dealer/byId',
        'submit_dealer_data':'/gd/dealer/master',
        'get_list_of_dealers':'/gd/get/dealer/listView',
        'delete_dealer_data':'/gd/delete/dealer/master',
        'get_audit_approvers':'/user/approvers',
        'create_update_checklist':'/createUpdate/checkList',
        'get_allowed_masters':'/gd/allowedmasters',
        'delete_audit_data':'/delete/audit',
        'get_am_checklist':"/getAmCheckList",
        'get_master_checklist':'/getMasterCheckList',
        'gd_masterdata_update':'/gd/masterdataupd',
        'get_submission_list':'/audit/submissionlist',
        'get_audit_checklist':'/audit/achievementCheckList',
        'submit_dealer_checklist':'/audit/dealer/achievementdata',
        'get_audit_approvals':'/audit/approverlist',
        'approve_audit_action':"/audit/approveReject",
        'active_inactive_dealer':'/gd/activeInactive/dealer',
        'genrate_excel_for_headers':'/gd/generate/excel/headers',
        'upload_master_data':'/gd/upload/master',
       
        //Notifications
        "get_notifications":"/notification/getNotifications",
        "get_notifications_counts":"/notification/getNotifyGroupCounts",
        "update_notifications":"/notification/updateNotifications",
        "update_all_notifications":"/notification/markAsReadAllNotifications",
        "get_header_notification_counts":"/notification/getbellnotificounts",

        //Attachments
        "upload_attachments":"/am/docupload",
        "download_attachments":"/am/docdownload",
        "view_attachments":"/am/docview",
        "upload_doc_attachment":"/rms/docupload",

        //Dashbaord
        'get_dashboard_data':'/getDashboardData',

        //ic
        'get_ic':'/ic',
        'upload_ic_attachments':'/ic/updIncentiveScore',
        'refresh_ic':'/ic/aggregate',

        //settings
        'get_group_settings':'/gd/settings/',
        'update_settings':'/gd/settings/addorupdate',
        'download_audit_doc':'/audit/submissionDataById',

        //audit planner data
        'get_planner_data':'/audit/planner',

        //Incentive Release
        'audit_release':'/audit/release',

        //Excel report
        'download_checklist':'/reports/checklistdata',

        //Revert Approval
        'revert_audit_status':'/revert/audit',

        //Final Summary
        'get_fsd':'/ic/finalsummary',

        //Audit Log
        'get_audit_log':'/audit/log',
        
        //User Manuals
        'get_user_manuals':'/hm/read-manual',
        'download_manual':'/hm/download-manual',

        //download pdf
        'download_pdf_files':'/reports/checklistdata/pdf',

        //download templates
        'download_zip_file':'/reports/templates',
        'user_classifieds':'/clf/types',
        'add_to_cart':'/clf/addtocart',
        'cart_items_list':'/clf/cartitems',
        'customer_data':'/clf/customer',
        'classified_dashboard':'/clf/dashboard/list',
        'classified_counts':'/clf/dashboard/counts',
        'classified_rates':'/clf/rates',
        'classified_templates':'/clf/templates',
        "payment_history":"/clf/payments",
        "delete_cart_item":"/clf/delete",
        "get_cart_item_details":"/clf/viewClfItem",
        "get_cart_count":"/clf/getcartcount",
        "user_registration":"/user/registration",
        "get_states":"/process/gd/states",
        "get_city":"/process/gd/city",
        "get_booking_Units":"/process/gd/bookingunits",
        "approove_item":"/clf/approveclf",
        "ads_pdf_download":"/clf/adspdf",
        "classified_download_status_list":"/clf/download/status/list",
        "sync_to_sap":"/dataload/sync/sap",
        "Pending_payment_list":"/clf/payment/list",
        "update_payment_tsdata":"/clf/pmt/reshndlr",
        "get_billdesk_payment_options":"/clf/pmt/options",
        "get_clf_rates":"/gd/clf/rates",
        "add_clf_rates":"/gd/add/clfrates",
        "delete_clf_rates":"/gd/delete/clfrates",
        "prepare_request":"/clf/prepare/request",
         "classified_download_excel":"/clf/download/status/excelList",

        "get_template_headers":"/gd/templates/headers",

        //get user log in lig out history
        "get_user_logged_history":"/user/loginhistory",

        "create_refund":"/clf/prepare/request/refund",
        "payment_refund_data":"/clf/payments/refund",

        "get_previous_tran_details" : "/clf/get/transactiondetails",
        "get_encoded_string" :"/clf/getencodedstring",
        "send_email_schedulin_team":"/clf/sendemailtoschedulingteam",



    }
    let hostContextPath: string = this.getConfigurations();
    return hostContextPath + "" + apiUrls[apiKey];
  }

}
